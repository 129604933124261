<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" style="max-height: 90px;" alt="logo"></a>
                    <p style="margin-top:-10px">
                        Lo studio Chianese & Partners nasce nel 2012 per volontà del dott. Luigi Chianese, professionista specializzato nella consulenza del lavoro.
                    </p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/chianeseepartners/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/chianeseepartners/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>LINK</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>LOGIN</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Menù</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>DOVE SIAMO</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Zona Consortile ASI<br>81030 Teverola (CE)</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+390815030566">Telefono: (+39) 081-5030566</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:segreteria@chianeseepartners.com">segreteria@chianeseepartners.com</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+393762143890">Lunedì-Venerdì<br>09:00 ÷ 18:00</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2024 Chianese & Partners - realizzato da <a target="_blank" href="https://www.discentya.it/">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-2 col-md-2">
                    <ul>
                        <li><a href="https://www.chianeseepartners.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
